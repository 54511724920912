// Fonts
@import "fonts";
// Variables
@import 'variables';

@import "mixins";

@import "admin/admin";

@import "brand";

@import "general";
// Bootstrap
//@import '~bootstrap/scss/bootstrap';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~filepond/dist/filepond.min.css';
