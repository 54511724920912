#admin_sidebar_main {
    overflow-y: auto;
    overflow-y: overlay;
    //background-color: #003B90;
}
@include scrollbar-thin('#admin_sidebar_main');

#admin_sidebar_main {
    i {
        &.fa-solid, &.fa-brand, &.fa-light, &.fa-duotone, &.fa-thin, &.fa-regular {
            width: 20px;
            height: auto;
        }
    }
}

