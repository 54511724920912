.page-content {
    max-height: calc(100% - 64px);
    overflow: auto;
}

.filepond--drip {
    background-color: #6689bc!important;
}

.checkbox-bulk-mail:checked + .check-icon {
    display: flex;
}
//This isn't pulling through properly on the server side when running webpack. Manually adding it here:
.scale-n1 {
    --tw-scale-x: -1;
    --tw-scale-y: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}


body.auth-body {
    background-image: url("/img/auth-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.text-white .links-showing {
    color: #fff;
}
