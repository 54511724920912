@mixin scrollbar-thin ($selector, $bg : #f5f5f5, $fg : #000000, $shadow : rgba(0,0,0,0.3)) {
    #{$selector}::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: $bg;
    }

    #{$selector}::-webkit-scrollbar
    {
        width: 6px;
        background-color: $bg;
    }

    #{$selector}::-webkit-scrollbar-thumb
    {
        background-color: $fg;
    }
}
